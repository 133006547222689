import React, { useCallback } from 'react'
import { GoogleMap, useJsApiLoader, Marker } from '@react-google-maps/api';
import MarkerImage from '../images/marker.svg'

const containerStyle = {
  width: '100%',
  height: '100%'
};

const center = {
  lat: 34.7073500,
  lng: 135.49558932,
};

export const MyGoogleMap = () => {
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: 'AIzaSyArH-mRooE9JBAWIxN-5L1oJFmULtxtug8'
  })


  const onLoad = useCallback(function callback(map) {
    // const bounds = new window.google.maps.LatLngBounds();
    // console.log(bounds)
    // map.fitBounds(bounds);
    // setMap(map)
  }, [])

  const onUnmount = useCallback(function callback(map) {
    // setMap(null)
  }, [])

  return isLoaded ? (
    <div className="google-map-wrapper">
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={center}
        zoom={17}
        onLoad={onLoad}
        onUnmount={onUnmount}
      >
        <Marker
          icon={MarkerImage}
          position={center}
        />
      </GoogleMap>
    </div>
  ) : <></>
}
