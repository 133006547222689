import React from 'react'
import Layout from '../components/layout'
import SEO from '../components/seo'
import { MyGoogleMap } from '../components/googlemap'

import kv from '../images/company-kv.jpg'

const Company = ({ location }) => (
  <Layout location={location} title="Company" subTitle="企業情報" kv={kv}>
    <SEO title="企業情報" />
    <section className="information">
      <div>
        <dl>
          <dt>会社名</dt>
          <dd>株式会社スマートホーム</dd>
        </dl>
        <dl>
          <dt>創業</dt>
          <dd>2018年10月</dd>
        </dl>
        <dl>
          <dt>代表者</dt>
          <dd>孫 寛貴</dd>
        </dl>
        <dl>
          <dt>所在地</dt>
          <dd><address>〒530-0012<br />
  大阪府大阪市北区芝田2丁目8-11<br />
  共栄ビル3階</address></dd>
        </dl>
        <dl>
          <dt>事業内容</dt>
          <dd>
          太陽光発電システム及び蓄電池システム等の<br />販売・施工・メンテナンス
          </dd>
        </dl>
      </div>
      <MyGoogleMap />
    </section>
  </Layout>
)

export default Company
